export default (enrollment) => {
  const enrollmentData = {
    prevEnrollmentId: enrollment.id,
    childData: {
      newChild: false,
      parentId: enrollment.parentId,
      dependentId: enrollment.dependentId,
      firstName: enrollment.child.firstName,
      lastName: enrollment.child.lastName,
      childId: enrollment.childId,
    },
    familyType: 0,
    enableAutopay: !!enrollment.autopay,
    autopayFrequency: enrollment.autopay?.autopayFrequency,
    autopayTuitionAmount: enrollment.autopay?.amount,
    paymentDay: enrollment.autopay?.day,
    scheduledAt: enrollment.autopay?.collectionDate,
    careFrom: enrollment.autopay?.formattedTuitionCycleDate,
    recurringLineItems: enrollment.autopay?.recurringLineItems,
    lastDay: enrollment.endDate,
    frequency: enrollment.tuitionCycle,
    startDate: enrollment.startDate,
    lastTour: enrollment.lastTour,
    lastPayment: enrollment.lastPayment,
  };

  if (enrollment.enrollmentRequest?.tourMessage) {
    enrollmentData.tourInstructions = enrollment.enrollmentRequest.tourMessage;
  }

  if (enrollment.enrollmentPayment) {
    const feeItems = enrollment.enrollmentPayment.paymentRequestItems.map((item) => ({
      amount: item.amount,
      test: item.text,
    }));
    enrollmentData.fees = feeItems;
  }

  return enrollmentData;
};
